<template>
  <!-- 路内停车场 —— 设备管理--嵌入式地磁 -->
  <div class="ParkingInformation">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
      @submit.native.prevent>
      <el-form-item label="停车场：" v-if="this.getUserInfo.user_name === 'admin'">
        <el-select popper-class="my-select" v-model="queryForm.parking_id" filterable clearable default-first-option
          placeholder="请选择停车场" @change="onSubmit">
          <el-option v-for="item in getAllParkingList(1)" :key="item.parking_id" :label="item.parking_name"
            :value="item.parking_id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="序列号：">
        <el-input v-model="queryForm.device_serial" maxlength="30" @keyup.enter.native="onSubmit"
          @input="(e) => (queryForm.device_serial = validFacility(e))" placeholder="请输入序列号"></el-input>
      </el-form-item>
      <el-form-item label="设备名称：">
        <el-input v-model="queryForm.device_name" maxlength="30" @keyup.enter.native="onSubmit"
          @input="(e) => (queryForm.device_name = validSpace(e))" placeholder="请输入设备名称"></el-input>
      </el-form-item>
      <el-form-item label="状态：">
        <el-select popper-class="my-select" placeholder="请选择设备状态" clearable v-model="queryForm.device_state"
          @change="onSubmit" @clear="queryForm.device_state = null">
          <!-- <el-option label="全部" :value="0"></el-option> -->
          <el-option label="在线" :value="1"></el-option>
          <el-option label="离线" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <le-card title="嵌入式地磁">
      <template slot="heard-right">
        <div>
          <lbButton type="err" icon="shanchu1" @click="batchDel"
            v-if='isShowBtn("ef9e381e599c48ab850ab237") || isShowBtn("b5fde25733954b9b974fd0d0")'>删除</lbButton>
          <lbButton icon="xinzeng" @click="openAddForm()"
            v-if='this.getUserInfo.user_name != "admin" && (isShowBtn("c9953c2551b84c2fa1fc15bd") || isShowBtn("38002c0b49f54c679d08b323"))'>
            新增</lbButton>
        </div>
      </template>
      <div class="box-card" style="text-align: left;">
        <!-- 主要内容 -->
        <!-- 表格 -->
        <!-- 使用插件生产表格 -->
        <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
          @size-change="sizeChange" @current-change="currentChange">
          <template slot-scope="{row}" slot="current_state">
            <div>{{ row.current_state == 0 ? "空闲" : "占用" }}</div>
          </template>
          <template slot-scope="{row}" slot="device_state">
            <div :style="row.device_state == 2 ? 'color: #ff0000;' : 'color: #18943b;'">
              {{ row.device_state == 2 ? "离线" : "在线" }}
            </div>
          </template>
          <template slot-scope="{row}" slot="parking_name">
            <div :style="row.parking_name != '未配置' ? '' : 'color: #aaa;'">{{ row.parking_name }}</div>
          </template>
          <template slot-scope="{row}" slot="create_time">
            {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
          </template>
          <template slot-scope="{row}" slot="menu">
            <lbButton type="succes" icon="chakan" hint="查看" @click="lookGeomagnetic(row)"></lbButton>
            <lbButton type="warning" icon="bianji" hint="编辑" @click="openEditForm(row)"
              v-if='isShowBtn("af9dddfb1b004827a2ac1d83") || isShowBtn("1a61d0a3debf474aa44e2f6a")'></lbButton>
          </template>
        </avue-crud> -->
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="50" align="center" header-align="center"></el-table-column>
          <el-table-column prop="geomagnetic_serial" label="序列号"></el-table-column>
          <el-table-column prop="geomagnetic_name" label="设备名称"></el-table-column>
          <el-table-column prop="geomagnetic_brand_name" label="设备品牌"></el-table-column>
          <el-table-column prop="geomagnetic_model_name" label="设备型号"></el-table-column>
          <el-table-column prop="electricity" label="电量" width="80"></el-table-column>
          <el-table-column prop="current_state" label="车位状态" width="100">
            <template slot-scope="scope">
              <div>{{ scope.row.current_state == 0 ? "空闲" : "占用" }}</div>
            </template>
          </el-table-column>
          <el-table-column label="状态" width="100">
            <template slot-scope="scope">
              <div :style=" scope.row.device_state == 2 ? 'color: #ff0000;' : 'color: #18943b;'">
                {{ scope.row.device_state == 2 ? "离线" : "在线" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="停车场" v-if="this.getUserInfo.user_name === 'admin'">
            <template slot-scope="scope">
              <div :style="scope.row.parking_name != '未配置' ?  '' : 'color: #aaa;'">{{ scope.row.parking_name}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="create_time" label="创建时间">
            <template slot-scope="scope">
              {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <lbButton type="succes" icon="chakan" hint="查看" @click="lookGeomagnetic(scope.row)"></lbButton>
              <lbButton type="warning" icon="bianji" hint="编辑" @click="openEditForm(scope.row)" v-if='isShowBtn("af9dddfb1b004827a2ac1d83") || isShowBtn("1a61d0a3debf474aa44e2f6a")'></lbButton>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" >
        </el-pagination>
      </div>
    </le-card>

    <!-- 新增对话框 -->
    <lebo-dialog append-to-body :title="title" :isShow="isAddDialog" width="30%" @close="closeAddDialogVisible()"
      footerSlot>
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="120px" class="demo-ruleForm">
        <el-form-item label="设备名称：" prop="geomagnetic_name">
          <el-input v-model="addForm.geomagnetic_name" placeholder="请输入设备名称" maxlength="30"
            @input="(e) => (addForm.geomagnetic_name = validSpace(e))"></el-input>
        </el-form-item>
        <el-form-item label="设备品牌：" prop="geomagnetic_brand_name">
          <el-select popper-class="my-select" v-model="addForm.geomagnetic_brand_name" placeholder="请选择设备品牌"
            @change="brandChange">
            <el-option v-for="(item, index) in brandList" :key="index" :label="item.brand_name"
              :value="item.brand_name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备型号：" prop="geomagnetic_model_name">
          <el-select popper-class="my-select" v-model="addForm.geomagnetic_model_name" placeholder="请选择设备型号"
            @change="modelChange">
            <el-option v-for="(item, index) in modelList" :key="index" :label="item.model_name"
              :value="item.model_name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="序列号：" prop="geomagnetic_serial">
          <el-input v-model="addForm.geomagnetic_serial" maxlength="30" placeholder="请输入序列号"
            @input="(e) => (addForm.geomagnetic_serial = validFacility(e))"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" @click="closeAddDialogVisible()">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click="submitAddForm()" v-preventReClick>保 存</lbButton>
      </span>
    </lebo-dialog>
    <lebo-dialog :isShow="showLookGeomagnetic" width="50%" title="查看设备" :footerSlot="true"
      @close="showLookGeomagnetic = false" closeOnClickModal>
      <lookGeomagnetic :geomagnetic_id="dialogGeomagneticId" v-if="showLookGeomagnetic"></lookGeomagnetic>
    </lebo-dialog>
  </div>
</template>

<script>
import { getGeomagnetismList, deleteGeomagnetism, addGeomagnetism, updateGeomagnetism, getBrandList, getModelList, getGeomagnetismDetails } from '@/api/deviceApi'
import { mapState, mapGetters, mapMutations } from 'vuex'
import lookGeomagnetic from './components/lookGeomagnetic/index.vue'
export default {
  components: { lookGeomagnetic },
  data () {
    return {
      parkingList: [],
      // 查询表单
      queryForm: {
        parking_id: '',
        PageSize: 10,
        PageIndex: 1,
        device_name: '',
        device_serial: '',
        device_state: null
      },
      total: 0,
      // 表格数据
      tableData: [],
      // 选中的表格数据
      multipleSelection: [],
      // 新增对话框
      isAddDialog: false,
      title: '',
      // 设备品牌下拉框
      brandList: [],
      // 设备型号下拉框
      modelList: [],
      // 新增表单
      addForm: {
        _id: '',
        geomagnetic_name: '',
        geomagnetic_brand_id: '',
        geomagnetic_brand_name: '',
        geomagnetic_model_id: '',
        geomagnetic_model_name: '',
        geomagnetic_serial: '',
        geomagnetic_model_pic: '',
        geomagnetic_type: ''
      },
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '序列号',
            prop: 'geomagnetic_serial'
          }, {
            label: '设备名称',
            prop: 'geomagnetic_name'
          }, {
            label: '设备品牌',
            prop: 'geomagnetic_brand_name'
          }, {
            label: '设备型号',
            prop: 'geomagnetic_model_name'
          }, {
            label: '电量',
            prop: 'electricity'
          }, {
            label: '车位状态',
            prop: 'current_state',
            slot: true
          }, {
            label: '状态',
            prop: 'device_state',
            slot: true
          }, {
            label: '停车场',
            prop: 'parking_name',
            slot: true
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      },
      // 新增表单验证
      addFormRules: {
        geomagnetic_name: [
          { required: true, message: '请输入设备名称', trigger: 'blur' }
        ],
        geomagnetic_brand_name: [
          { required: true, message: '请选择设备品牌', trigger: 'blur' }
        ],
        geomagnetic_model_name: [
          { required: true, message: '请选择设备型号', trigger: 'blur' }
        ],
        geomagnetic_serial: [
          { required: true, message: '请输入序列号', trigger: 'blur' }
        ]
      },
      showLookGeomagnetic: false, // 查看地磁弹框
      dialogGeomagneticId: '' // 地磁id
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
    }
    this.fnGetGeomagnetismList()
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
    this.parkingList = this.$store.state.parkingList
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapState('menuList', ['isRoutW']),
    ...mapGetters(['getUserInfo', 'getAllParkingList'])
  },
  methods: {
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    async fnGetGeomagnetismList () {
      const res = await getGeomagnetismList({
        parking_id: this.queryForm.parking_id,
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex,
        device_name: this.queryForm.device_name,
        device_serial: this.queryForm.device_serial,
        device_state: this.queryForm.device_state ? this.queryForm.device_state : null
      })
      // console.log(res);
      this.total = res.Data.TotalCount
      this.tableData = res.Data.DataList
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetGeomagnetismList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetGeomagnetismList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetGeomagnetismList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      this.fnGetGeomagnetismList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
      // console.log(`当前页: ${val}`);
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetGeomagnetismList()
    // },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      // console.log(val);
      this.multipleSelection = val
    },
    // // 选中的数据
    // selectionChange (list) {
    //   this.multipleSelection = list
    // },
    // 批量删除
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除选中的地磁设备, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var obj = {}
          var arr = []
          // this.$msg.success('删除成功!');
          for (var i = 0; i < this.multipleSelection.length; i++) {
            arr.push(this.multipleSelection[i]._id)
            if (arr.length === this.multipleSelection.length) {
              obj._ids = arr
              this.fnDeleteGeomagnetism(obj)
            }
          }
        }).catch(() => {
          this.$msg.info('已取消删除')
        })
      } else {
        this.$msg.warning('请选择要删除的地磁设备！')
      }
    },
    // 删除设备请求
    async fnDeleteGeomagnetism (obj) {
      const res = await deleteGeomagnetism(obj)
      // console.log(res);
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fnGetGeomagnetismList()
    },
    // 新增设备
    openAddForm () {
      this.isAddDialog = true
      this.title = '新增设备'
      this.fnGetBrandList()
    },
    // 修改设备
    openEditForm (row) {
      // console.log(row);
      this.isAddDialog = true
      this.title = '编辑设备'
      this.fnGetBrandList()
      this.fnGetModelList(row.geomagnetic_brand_id)
      this.addForm._id = row._id
      this.addForm.geomagnetic_name = row.geomagnetic_name
      this.addForm.geomagnetic_brand_id = row.geomagnetic_brand_id
      this.addForm.geomagnetic_brand_name = row.geomagnetic_brand_name
      this.addForm.geomagnetic_model_id = row.geomagnetic_model_id
      this.addForm.geomagnetic_model_name = row.geomagnetic_model_name
      this.addForm.geomagnetic_serial = row.geomagnetic_serial
      this.addForm.geomagnetic_model_pic = row.geomagnetic_model_pic
      this.addForm.geomagnetic_type = row.geomagnetic_type
    },
    // 设备品牌下拉框
    async fnGetBrandList () {
      const res = await getBrandList({
        device_type: 2
      })
      // console.log(res);
      this.brandList = res.Data
    },
    // 当设备品牌下拉框发生改变时
    brandChange (val) {
      // console.log(val);
      var arr = this.brandList.filter(item => {
        return item.brand_name === val
      })
      // console.log(arr);
      this.addForm.geomagnetic_brand_name = arr[0].brand_name
      this.addForm.geomagnetic_brand_id = arr[0]._id
      this.fnGetModelList(arr[0]._id)
      this.addForm.geomagnetic_model_id = ''
      this.addForm.geomagnetic_model_name = ''
      this.addForm.geomagnetic_model_pic = ''
      this.addForm.geomagnetic_type = ''
    },
    // 设备型号下拉框
    async fnGetModelList (id) {
      const res = await getModelList({
        brand_id: id,
        device_type: 2
      })
      // console.log(res);
      this.modelList = res.Data
    },
    // 当设备型号下拉框发生改变时
    modelChange (val) {
      // console.log(val);
      var arr = this.modelList.filter(item => {
        return item.model_name === val
      })
      // console.log(arr);
      this.addForm.geomagnetic_model_id = arr[0]._id
      this.addForm.geomagnetic_model_name = arr[0].model_name
      this.addForm.geomagnetic_model_pic = arr[0].pic
      this.addForm.geomagnetic_type = arr[0].model_type
    },
    // 关闭新增编辑对话框，重置数据
    closeAddDialogVisible () {
      this.isAddDialog = false
      // 移除校验结果
      this.$refs.addFormRef.resetFields()
      this.addForm._id = ''
      this.addForm.geomagnetic_name = ''
      this.addForm.geomagnetic_brand_id = ''
      this.addForm.geomagnetic_brand_name = ''
      this.addForm.geomagnetic_model_id = ''
      this.addForm.geomagnetic_model_name = ''
      this.addForm.geomagnetic_serial = ''
      this.addForm.geomagnetic_model_pic = ''
      this.addForm.geomagnetic_type = ''
      this.brandList = []
      this.modelList = []
    },
    // 新增/编辑
    submitAddForm () {
      // 对整个表单进行校验
      this.$refs.addFormRef.validate((valid) => {
        // 成功
        if (valid) {
          var obj = {}
          obj = {
            geomagnetic_name: this.addForm.geomagnetic_name,
            geomagnetic_brand_id: this.addForm.geomagnetic_brand_id,
            geomagnetic_brand_name: this.addForm.geomagnetic_brand_name,
            geomagnetic_model_id: this.addForm.geomagnetic_model_id,
            geomagnetic_model_name: this.addForm.geomagnetic_model_name,
            geomagnetic_serial: this.addForm.geomagnetic_serial,
            geomagnetic_model_pic: this.addForm.geomagnetic_model_pic,
            geomagnetic_type: this.addForm.geomagnetic_type
          }
          // console.log('新增/编辑请求');
          if (this.title === '新增设备') {
            // console.log(obj);
            this.fnAddGeomagnetism(obj)
          } else {
            obj._id = this.addForm._id
            // console.log(obj);
            this.fnUpdateGeomagnetism(obj)
          }
        } else {
          return false
        }
      })
    },
    // 新增地磁设备请求
    async fnAddGeomagnetism (obj) {
      // this.isAddDialog = false
      const res = await addGeomagnetism(obj)
      // console.log(res);
      this.isAddDialog = false
      this.onSubmit()
      this.closeAddDialogVisible()
    },
    // 编辑地磁设备请求
    async fnUpdateGeomagnetism (obj) {
      const res = await updateGeomagnetism(obj)
      // console.log(res);
      this.isAddDialog = false
      this.fnGetGeomagnetismList()
      this.closeAddDialogVisible()
    },
    // 查看地磁设备
    lookGeomagnetic (row) {
      this.showLookGeomagnetic = true
      this.dialogGeomagneticId = row._id
      // this.$router.push({
      //   path: '/look-geomagnetic',
      //   query: {
      //     geomagnetic_id: row._id
      //   }
      // })
    }
  }
}
</script>

<style scoped lang="less">.el-select {
  width: 100%;
}

.ParkingInformation {
  margin-top: 20px;
}</style>
