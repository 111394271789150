<template>
    <!-- 路内停车场 ————> 查看地磁 -->
    <div class="box-card" style="text-align: left;margin-top:0;">
      <!-- 主要内容 -->
      <div class="content">
        <el-form label-position="right" label-width="120px" :model="detailsList" class="left">
          <!-- <div class="title">基本信息</div>
          <el-divider></el-divider> -->
          <el-form-item label="设备名称：">{{detailsList.geomagnetic_name}}</el-form-item>
          <el-form-item label="设备品牌：">{{detailsList.geomagnetic_brand_name}}</el-form-item>
          <el-form-item label="设备型号：">{{detailsList.geomagnetic_model_name}}</el-form-item>
          <!-- <div class="title" style="margin-top:100px;">硬件属性</div>
          <el-divider></el-divider> -->
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="序列号：">{{detailsList.geomagnetic_serial}}</el-form-item>
              <!-- <el-form-item label="硬件版本：">{{detailsList.hardware_version}}</el-form-item> -->
              <el-form-item label="电压：">{{detailsList.voltage}}</el-form-item>
              <el-form-item label="电量：">{{detailsList.electricity}}</el-form-item>
              <el-form-item label="工作模式：">{{detailsList.work_mode}}</el-form-item>
              <el-form-item label="当前状态：">{{detailsList.current_state}}</el-form-item>
              <el-form-item label="状态计数：">{{detailsList.state_count}}</el-form-item>
              <el-form-item label="设备状态：">
                <div :style="detailsList.device_state == 2 ? 'color: #ff0000;' : 'color: #18943b;'">{{detailsList.device_state == 2 ? "离线" : "在线" }}</div>
              </el-form-item>
              <el-form-item label="状态更新时间：">
                {{ $moment(detailsList.heartbeat_time).format("YYYY-MM-DD HH:mm:ss") }}
              </el-form-item>

            </el-col>
          </el-row>

        </el-form>
        <div class="right">
          <el-image style="width: 100%;" :src="detailsList.geomagnetic_model_pic" v-if="detailsList.geomagnetic_model_pic">
          </el-image>
          <div class="innerText">设备图片</div>
        </div>
      </div>
    </div>
  </template>

<script>
import { getGeomagnetismDetails } from '@/api/deviceApi'
import { mapMutations, mapState } from 'vuex'
export default {
  props: ['geomagnetic_id'],
  data () {
    return {
      detailsList: {}
    //   geomagnetic_id: null
    }
  },
  created () {
    // this.geomagnetic_id = this.$route.query.geomagnetic_id
    this.fngetGeomagnetismDetails()
  },
  mounted () { },
  computed: {
    ...mapState('menuList', ['isRoutW'])
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    // 地磁详情/编辑地磁界面
    async fngetGeomagnetismDetails () {
      const res = await getGeomagnetismDetails({
        geomagnetic_id: this.geomagnetic_id
      })
      // console.log(res);
      this.detailsList = res.Data
    },
    // 返回地磁页面
    goBack () {
      this.setQueryStatus(2)
      this.$router.go(-1)
    }
  }
}
</script>

  <style scoped lang="less">
  .content {
    width: 100%;
    padding:0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: start;
  }
  .title {
    width: 100%;
    text-align: left;
    font-size: 18px;
    font-weight: 700;
    color: #000;
  }
  .content .left {
    width: 70%;
  }
  .left .el-form-item__content {
    text-align: left;
  }
  .left .el-row {
    width: 100%;
  }
  .left .el-form-item {
    margin-bottom: 0px;
  }
  .content .right {
    width: 40%;
    background-color: #FAFAFA;
    .innerText{
      width: 100%;
      text-align: center;
      color: #fff;
      background-color: #7D7D7D;
    }
  }
  </style>
